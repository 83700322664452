// const moment = require('moment');
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { Navigation, Pagination, Autoplay, EffectFade, Swiper } from 'swiper'
// Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);
gsap.registerPlugin(ScrollTrigger);
import 'slick-carousel';

import { index } from "./components/index";

index();



gsap.fromTo('.inpage .slash__right',
	{
		// autoAlpha: 0,
		x: "100%",
		y: "-100%"
	},
	{
		// autoAlpha: 1,
		x: "0%",
		y: "0%", //ここでアニメーションさせたい内容を書く
		scrollTrigger: {
			trigger: '.inpage .slash__right',
			start: 'bottom center',
			end: 'bottom center',
			//markers: true
		}
	});

gsap.fromTo('.inpage .slash__left',
	{
		// autoAlpha: 0,
		x: "-100%",
		y: "100%"
	},
	{
		// autoAlpha: 1,
		x: "0%",
		y: "0%", //ここでアニメーションさせたい内容を書く
		scrollTrigger: {
			trigger: '.inpage .slash__left',
			start: 'top center',
			end: 'top center',
			//markers: true
		}
	});


$(function () {
	$(".slider-for").slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		fade: true,
		asNavFor: ".slider-nav",
		autoplay: true,
		autoplaySpeed: 10000,
		infinite: false
	})
	$(".slider-nav").slick({
		slidesToShow: 4,
		slidesToScroll: 4,
		asNavFor: ".slider-for",
		arrows: false,
		dots: false,
		centerMode: false,
		focusOnSelect: true,
		infinite: false
	})


	$(document).on('click', '.nav__bar', function () {
		$('.nav').toggleClass('is_active');
	});
})
