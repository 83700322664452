// const moment = require('moment');
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { Navigation, Pagination, Autoplay, EffectFade, Swiper } from 'swiper'
// Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);
gsap.registerPlugin(ScrollTrigger);

export function index() {
	$(function () {
		gsap.fromTo('.toppage__concept .slash__right',
			{
				// autoAlpha: 0,
				x: "80%",
				y: "-80%"
			},
			{
				// autoAlpha: 1,
				x: "0%",
				y: "0%", //ここでアニメーションさせたい内容を書く
				scrollTrigger: {
					trigger: '.toppage__concept .slash__right',
					start: 'bottom center',
					end: 'bottom center',
					//markers: true
				}
			});

		gsap.fromTo('.toppage__concept .slash__left',
			{
				// autoAlpha: 0,
				x: "-80%",
				y: "80%"
			},
			{
				// autoAlpha: 1,
				x: "0%",
				y: "0%", //ここでアニメーションさせたい内容を書く
				scrollTrigger: {
					trigger: '.toppage__concept .slash__left',
					start: 'top center',
					end: 'top center',
					//markers: true
				}
			});

		gsap.fromTo('.toppage__news .slash__right',
			{
				// autoAlpha: 0,
				x: "80%",
				y: "80%"
			},
			{
				// autoAlpha: 1,
				x: "0%",
				y: "0%", //ここでアニメーションさせたい内容を書く
				scrollTrigger: {
					trigger: '.toppage__news .slash__right',
					start: 'top bottom',
					end: 'top top',
					//markers: true
				}
			});

		gsap.fromTo('.toppage__news .slash__left',
			{
				// autoAlpha: 0,
				x: "-80%",
				y: "-80%"
			},
			{
				// autoAlpha: 1,
				x: "0%",
				y: "0%", //ここでアニメーションさせたい内容を書く
				scrollTrigger: {
					trigger: '.toppage__news .slash__left',
					start: 'bottom center',
					end: 'bottom center',
					//markers: true
				}
			});
	});

}
